import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import Slider from "react-slick"
import arrow from "../../images/fleet/next-white.svg"
import device from "../device"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  .slick-arrow {
    z-index: 100;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${device.small`top: 120%;`}
    img {
      width: 40px;
      display: block;
    }
    &.slick-next {
      right: -15%;
      ${device.small`right: 20%;`}
    }
    &.slick-prev {
      left: -15%;
      transform: translateY(-50%) rotate(180deg);
      ${device.small`left: 20%;`}
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
`
const Arrow = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrow} alt="Slider Button" />
    </div>
  )
}

const Gallery = ({ images }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  }
  return (
    <Wrapper>
      <Slider {...settings}>
        {images.map((ele, index) => (
          <div className="swiper-slide" key={index}>
            <Img
              className="gallery-image"
              fluid={ele.localFile.childImageSharp.fluid}
              alt={ele.alt_text}
            />
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default Gallery
