import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  }
`

export default function View360({ iframe }) {
  return (
    <Wrapper>
      <div className="container">
        <iframe
          title="Ford F-450"
          loading="lazy"
          src={iframe}
          style={{ width: "100%" }}
          scrolling="no"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      </div>
    </Wrapper>
  )
}
