import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import device from "../device"
import GreenHeading from "../heading"
import { ReserveNow, LearnMore } from "../hooks/useLinks"
import Slider from "./fleet-slider"

const Wrapper = styled.div`
  padding-bottom: ${props =>
    props.sectionName === "why-king" ? "6rem" : "2rem"};
  position: relative;
  margin-top: ${props =>
    props.sectionName === "why-king" ? "-8rem" : "-5rem"};
  ${device.large`margin-top: ${props =>
    props.sectionName === "why-king" ? "-15rem" : "-10rem"};`}
  ${device.small`padding: 0; margin-top: 0;`}
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    .heading {
      font-size: 2.5rem;
      line-height: 0.8;
      ${device.small`font-size: 2rem;`}
      .secondary-heading {
        color: var(--white);
        margin-left: 3rem;
        ${device.small`margin-left: 2rem;`}
      }
    }
  }
  .overlay {
    z-index: -6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      var(--darkgrey),
      var(--darkgrey) 30%,
      var(--white) 30%,
      var(--white) 80%,
      var(--darkgrey) 80%
    );
    ${device.large`
      background: linear-gradient(
        to bottom,
        var(--darkgrey),
        var(--darkgrey) 30%,
        var(--white) 30%,
        var(--white) 85%,
        var(--darkgrey) 85%
      );
    `}
    ${device.small`
      background: linear-gradient(
        to bottom,
        var(--darkgrey),
        var(--darkgrey) 25%,
        var(--white) 25%,
        var(--white) 100%
      );
    `}
  }
  .links {
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
    text-align: center;
    ${device.small`display: none;`}
    a {
      display: block;
      margin: 0 1rem;
    }
    .learn-more {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: var(--white);
      padding: calc(0.5rem - 1px) 1.5rem;
      border: 1px solid var(--white);
    }
  }
`

const Categories = styled.div`
  margin-top: 2rem;
  background: var(--white);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${device.small`background: var(--darkgrey); box-shadow: none; margin-top: 1rem;`}
  .category-wrapper {
    padding: 1.3rem 0;
    color: var(--black);
    position: relative;
    cursor: pointer;
    ${device.small`color: var(--white); padding: 0;`}
    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid var(--green);
      opacity: 0;
      ${device.small`display: none;`}
    }
    &.selected {
      background: var(--green);
      color: var(--white);
      ${device.small`background: transparent; color: var(--green);`}
      &::after {
        opacity: 1;
      }
      .category-inner {
        ${device.small`border-bottom: 1px solid var(--green); padding: 0.2rem;`}
      }
    }
    &:last-child .category {
      border-right: none;
    }
  }
  .category {
    display: block;
    padding: 0 2.3rem;
    font-size: 0.9rem;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    ${device.small`margin: 0.5rem 0;`}
  }
`

const Fleet = ({ preActive, sectionName, removeHeading }) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpFleetCategories {
        edges {
          node {
            name
            fleet {
              title
              acf {
                bg_text
                carImage: main_image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                carImageMobile: main_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 280, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                passengers
                text
                iframe
                gallery {
                  gallery_image_1 {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  gallery_image_2 {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
                features {
                  name
                  acf {
                    image {
                      alt_text
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 30) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
                thumbnails {
                  thumbnail_gallery {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 350) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  thumbnail_view {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 350) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const edges = data.allWordpressWpFleetCategories.edges
  const posts = [].concat.apply(
    [],
    edges.map(({ node }) =>
      node.fleet.map(post => ({ ...post, category: node.name }))
    )
  )
  const categories = edges.map(({ node }) => ({
    name: node.name,
    index: posts.findIndex(ele => ele.category === node.name),
  }))

  let preActiveIndex = 0
  if (preActive) {
    preActiveIndex = posts.findIndex(ele => ele.category === preActive)
  }

  const [activeCategory, setActiveCategory] = useState(preActiveIndex)
  const [activeSlide, setActiveSlide] = useState(0)
  const [click, setClick] = useState(preActive ? true : false)

  function handleClick(e) {
    setActiveCategory(parseInt(e.currentTarget.getAttribute("data-index")))
    setClick(true)
  }

  return (
    <Wrapper sectionName={sectionName}>
      <div className="overlay"></div>
      <div className="container">
        {!removeHeading && (
          <h2 className="heading">
            <GreenHeading text="Our" />
            <br />
            <span className="secondary-heading">Fleet</span>
          </h2>
        )}
        <Categories>
          {categories.map(item => (
            <div
              className={
                item.index === activeCategory
                  ? "category-wrapper selected"
                  : "category-wrapper"
              }
              key={item.index}
              onClick={handleClick}
              data-index={item.index}
            >
              <span className="category">
                <span className="category-inner">{item.name}</span>
              </span>
            </div>
          ))}
        </Categories>

        <Slider
          posts={posts}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
          click={click}
          setClick={setClick}
          categories={categories}
        ></Slider>

        <div className="links">
          <LearnMore className="learn-more" />
          <ReserveNow />
        </div>
      </div>
    </Wrapper>
  )
}

export default Fleet
