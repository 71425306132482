import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import Slider from "react-slick"

import device, { sizes } from "../device"
import passengerIcon from "../../images/fleet/passenger icon.svg"
import arrow from "../../images/fleet/next.svg"
import view360 from "../../images/fleet/360-view.svg"
import imageGallery from "../../images/fleet/image-gallery.svg"
import NoResults, { PlaceholderImage } from "../noResults"
import Modal from "../modal"
import Gallery from "./gallery"
import View360 from "./view-360"

const Wrapper = styled.div`
  width: 80%;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  ${device.small`width: var(--spread);`}
  .arrows {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    ${device.small`width: 100%; top: 25%; transform: translate(-50%, -25%);`}
    .prev {
      transform: rotate(180deg);
    }
    .next{
      margin-left: 100%;
    }
    img {
      width: 30px;
      display: block;
      ${device.small`width: 20px;`}
      ${device.large`width: 50px;`}
    }
  }
  @media(min-width: 601px){
    .arrows{
      display: flex;
      justify-content: space-between;

      .next{
        margin-left: 0;
      }
    }
    
  }
`

const Car = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  .car-heading {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: 6rem;
    color: var(--white);
    opacity: 0.05;
    ${device.large`font-size: 8rem;`}
    ${device.small`display: none;`}
  }
  .car-image {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 40vmax;
    height: 30vmax;
    max-width: 1024px;
    max-height: 768px;
    ${device.small`width: 280px; height: 210px;`}
  }
  .car-image-placeholder {
    display: block;
    width: 40vmax;
    height: 30vmax;
    max-width: 1024px;
    max-height: 768px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .not-found {
      width: 200px;
      height: 200px;
      ${device.small`width: 50px; height: 50px;`}
    }
    ${device.small`width: 240px; height: 180px;`}
  }
  .car-wrapper {
    width: 65%;
    max-width: 900px;
    min-height: 150px;
    display: flex;
    margin: 0 auto;
    ${device.large`min-height: 160px;`}
    ${device.medium`width: 75%;`}
    ${device.small`display: block; width: 100%; min-height: auto;`}
    & > * {
      ${device.small`margin: 1rem 0 2rem 0;`}
    }
  }
  .car-description {
    flex: 1;
    .flex-wrapper {
      display: flex;
      ${device.small`justify-content: space-between;`}
    }
    .name {
      flex: 1;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;  
    }
    .passengers {
      align-self: flex-start;
      display: flex;
      align-items: center;
      font-size: 0.65rem;
      padding: 0.3rem 1rem;
      border-radius: 25px;
      background: var(--black);
      color: var(--white);
      ${device.small`padding: 0.5rem 1rem;`}
      embed {
        display: block;
        margin-right: 1rem;
        width: 12px;
        height 12px;
      }
      span {
        display: block;
      }
    }
    .text {
      margin-top: 0.5rem;
      font-size: 0.85rem;
      ${device.large`font-size: 0.9rem`}
    }
  }
  .car-specs {
    align-self: flex-end;
    display: flex;
    align-items: center;
    ${device.small`display: grid; grid-template-columns: repeat(6,1fr); justify-items: center; margin-top: 3rem;`}
    .spec {
      position: relative;
      border: 2px solid var(--green);
      border-radius: 10px;
      padding: 0.3rem;
      margin-left: 0.3rem;
      transition: background 0.2s;
      ${device.small`margin-left: 0;`}
      &:before {
        content: attr(data-spec);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50px);
        box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.2);
        background: var(--white);
        font-size: 0.6rem;
        padding: 0.5rem 0.3rem;
        border-radius: 5px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;    
        text-transform: uppercase;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.2s;
        ${device.small`transform: translate(-50%, -40px);`}
      }
      &:hover {
        background: var(--green);
        &:before {
          opacity: 1;
        }
        .spec-img {
          filter: var(--white-filter);
        }
      }
      .spec-img {
        display: block;
        width: 30px;
        height: 30px;
        filter: var(--green-filter);
      }
    }
  }
  .car-links {
    width: 65%;
    max-width: 900px;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    ${device.medium`width: 75%;`}
    ${device.small`width: 100%;`}
    .thumbnail {
      cursor: pointer;
      position: relative;
      width: 300px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${device.large`width: 360px; height: 240px;`}
      ${device.medium`width: 210px; height: 140px;`}
      ${device.small`height: 120px;`}
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        color: var(--white);
        img {
          width: 80px;
          height: auto;
          ${device.small`width: 40px;`}
        }
        span {
          display: block;
          text-transform: uppercase;
          font-size: 0.8rem;
        }
      }
      .placeholder {
        background: var(--grey);
        width: 100%;
        height: 100%;
      }
    }
    .view {
      margin-right: 4rem;
      ${device.small`margin-right: 1rem;`}

    }
    .gallery {
      .icon img {
        width: 70px;
        ${device.small`width: 30px;`}
      }
    }
  }
`

const FleetSlider = ({
  posts,
  activeCategory,
  setActiveCategory,
  activeSlide,
  setActiveSlide,
  click,
  setClick,
  categories,
}) => {
  const sliderRef = useRef(null)
  const [isModalActive, setModalActive] = useState(null)

  useEffect(() => {
    click && sliderRef.current.slickGoTo(activeCategory, true)
  }, [activeCategory, click])

  useEffect(() => {
    setClick(false)
    setActiveCategory(belongsTo(activeSlide))
    function belongsTo(currSlide) {
      const reversedCategories = [...categories].reverse()
      return reversedCategories.find(c => c.index <= currSlide).index
    }
  }, [activeSlide, setActiveCategory, setClick, categories])

  function handleClick(e) {
    setModalActive(e.currentTarget.dataset.index)
  }

  function handleNext() {
    sliderRef.current.slickNext()
  }

  function handlePrev() {
    sliderRef.current.slickPrev()
  }

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_, next) => setActiveSlide(next),
  }

  return (
    <Wrapper>
      <Slider {...settings} ref={sliderRef}>
        {posts.map((post, i) => (
          <Car key={i}>
            <h2 className="car-heading">
              {post.acf.bg_text ? post.acf.bg_text : post.title.split(" ")[0]}
            </h2>
            {post.acf.carImage && post.acf.carImage.localFile ? (
              <Img
                className="car-image"
                fluid={[
                  post.acf.carImageMobile.localFile.childImageSharp.fluid,
                  {
                    ...post.acf.carImage.localFile.childImageSharp.fluid,
                    media: `(min-width: ${sizes.small}px)`,
                  },
                ]}
                alt={post.acf.carImage.alt_text}
              />
            ) : null}

            <div className="car-wrapper">
              <div className="car-description">
                <div className="flex-wrapper">
                  <h3 className="name">{post.title}</h3>
                  <div className="passengers">
                    <embed src={passengerIcon} alt="Number of passengers" />
                    <span>{post.acf.passengers} PASSENGERS</span>
                  </div>
                </div>
                <p className="text">{post.acf.text}</p>
              </div>
              <div className="car-specs">
                {post.acf.features.map((spec, j) => (
                  <div className="spec" data-spec={spec.name} key={j}>
                    {spec.acf ? (
                      <Img
                        fluid={spec.acf.image.localFile.childImageSharp.fluid}
                        className="spec-img"
                        alt={spec.acf.image.alt_text}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            {(post.acf.thumbnails &&
              post.acf.thumbnails.thumbnail_view !== null &&
              post.acf.thumbnails.thumbnail_view.localFile
              ||
              post.acf.thumbnails &&
              post.acf.thumbnails.thumbnail_gallery &&
              post.acf.thumbnails.thumbnail_gallery.localFile) !== null &&
            <div className="car-links">
              <div
                className="view thumbnail"
                data-index="1"
                onClick={handleClick}
              >
                {post.acf.thumbnails &&
                post.acf.thumbnails.thumbnail_view !== null &&
                post.acf.thumbnails.thumbnail_view.localFile ? (
                  <Img
                    fluid={
                      post.acf.thumbnails.thumbnail_view.localFile
                        .childImageSharp.fluid
                    }
                    alt="360 view thumbnail"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  post.acf.thumbnails.thumbnail_view !== null ?
<div className="placeholder" />
                  :
                  null
                  
                )}
                {post.acf.thumbnails.thumbnail_view !== null &&
                <div className="icon">
                  <img src={view360} alt="360 deg View of the Car" />
                  <span>360 View</span>
                </div>
                }
              </div>

              <div
                className="gallery thumbnail"
                data-index="2"
                onClick={handleClick}
              >
                {post.acf.thumbnails &&
                post.acf.thumbnails.thumbnail_gallery &&
                post.acf.thumbnails.thumbnail_gallery.localFile ? (
                  <Img
                    fluid={
                      post.acf.thumbnails.thumbnail_gallery.localFile
                        .childImageSharp.fluid
                    }
                    alt="Gallery thumbnail"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  post.acf.thumbnails.thumbnail_view !== null ?
                    <div className="placeholder" />
:
null
                                      
                )}
                <div className="icon">
                  <img src={imageGallery} alt="Gallery images of the Car" />
                  <span>Image Gallery</span>
                </div>
              </div>
            </div>
}
          </Car>
        ))}
      </Slider>
      <div className="arrows">
        <img
          className="prev"
          src={arrow}
          alt="Slider Button"
          onClick={handlePrev}
        />
        <img
          className="next"
          src={arrow}
          alt="Slider Button"
          onClick={handleNext}
        />
      </div>

      {isModalActive && (
        <Modal setModalActive={setModalActive}>
          {isModalActive === "1" ? (
            posts[activeSlide].acf && posts[activeSlide].acf.iframe ? (
              <View360 iframe={posts[activeSlide].acf.iframe} />
            ) : (
              <NoResults text={"No view found"} />
            )
          ) : posts[activeSlide].acf &&
            Object.values(posts[activeSlide].acf.gallery).length ? (
            <Gallery
              images={Object.values(posts[activeSlide].acf.gallery).filter(
                i => i !== null
              )}
            />
          ) : (
            <NoResults text={"No images found"} />
          )}
        </Modal>
      )}
    </Wrapper>
  )
}

export default FleetSlider
